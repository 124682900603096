import { Box, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import LogoOnBlackSvg from "../img/logo-onboarding.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: theme.spacing(2, "auto", 3),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, "auto"),
    },
    "&&:hover": {
      background: "transparent",
    },
  },
  btn: {
    cursor: "default",
    "&:hover": {
      background: "transparent",
    },
  },
  svg: {
    height: "auto",
    maxWidth: 120,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(-2),
      maxWidth: 220,
    },
  },
}));

export const ReclaimLogo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <IconButton className={classes.btn} size="medium" aria-label="reclaim.ai" disableRipple disableFocusRipple disableTouchRipple>
        <LogoOnBlackSvg className={classes.svg} preserveAspectRatio="xMidYMid meet" />
      </IconButton>
    </Box>
  );
};
